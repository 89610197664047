.c-ant-table {
    .ant-table-row {
        &.warning {
            td {
                background-color: #fff3cd;
            }
        }
        &.inactive {
            td {
                background-color: #ffa39e;
            }
        }
    }
}